.main {
  min-height: 400px;
  margin-top: 105px;
  background-repeat: no-repeat;
  background-position: right center;
  background-size: cover;
  position: relative;

  @include mq(md) {
    margin-top: auto;
    height: auto;
    padding: 110px 0 40px;
    background-position: left center;
   }

  &__textBox {
    position: absolute;
    top: 30%;
    transform: translate(0, -30%);
    width: 45%;
    color: $white;

    @include mq(xxl, min) {
      width: 31%;
      top: 30%;
    }

    @include mq(md) {
      position: unset;
      top: unset;
      left: unset;
      transform: unset;
      width: 100%;
    }

    img {
      margin-top: -50px;
    }
    

    h1 {
      width: 90%;
      font-size: 32px;
      line-height: 36px;
      margin-bottom: 0;
      font-weight: 400;

      span {
        text-transform: uppercase;
        color: $primary;
      }
      
      @include mq(md) {
        font-size: 32px;
       line-height: 36px;
      }
    }
  }

  &__rules {
    background-color: $secondary;
    padding: 40px 50px;
    color: $white;

    @include mq(md) {
      padding: 40px 20px 0;
    }

    .border-right {
      border-right: 1px solid rgba($white, .13);

      @include mq(md) {
        border-right: none;
        border-bottom: 1px solid rgba($white, .13);
      }
    }

    .text {
      position: relative;
      top: 50%;
      transform: translate(0, -50%);
    }

    .button {
      position: relative;
      padding-bottom: 30px;

      a {
        margin: 0 auto !important;
      }
    }

    .step {
      display: flex;
      flex-direction: column;
      align-items: center;
      position: relative;
      top: 50%;
      transform: translate(0, -50%);
      padding: 35px;
      text-align: center;

      p {
        font-size: 21px;
        line-height: 24px;
        font-weight: 600;
        margin-bottom: 0;
        min-height: 72px;

        @include mq(md) {
          min-height: 52px;
        }

        &:first-of-type {
          color: $primary;
          font-size: 76px;
          line-height: 71px;
        }
      }
    }
  }

  &__topText {
    font-size: 45px;
    font-weight: 600;
    text-transform: uppercase;
    color: $primary;
    margin-bottom: 7px;
    text-shadow: 0 0 10px #221C4A;
  }

  &__desc {
    color: $white;
    font-size: 19px;
    line-height: 19px;

    @include mq(md) {
      width: 100%;
      font-size: 21px;
      line-height: 25px;
    }

    strong {
      display: block;
    }
  }

  &__awards {
    width: 100%;

    @include mq(md) {
      width: 100%;
      position: unset;
    }
  }
  &__prizes{
    padding-bottom: 20px;
    display: flex;
    gap: 30px;
    align-items: center;
    margin-left: -10px;

    @include mq(md) {
      justify-content: space-between;

      img {
        width: 45%;
      }
    }
  }
  &__buttons {
    padding-top: 40px;
    padding-bottom: 20px;
    display: flex;
    gap: 20px;
    align-items: center;
    margin-left: -10px;

    @include mq(md) {
      margin-left: 0;
      padding-bottom: 0;
      gap: 10px;
      padding-top: 15px;
      flex-direction: column;
      align-items: center;

      a {
        margin-bottom: 10px;
      }
    }
  }

  &__products {
    position: relative;
    padding: 50px 0 80px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

    img {
      width: 100%;
      margin-bottom: 10px;
    }

    @include mq(md) {
      padding: 60px 0;
      margin-top: 0;
    }

    .text {
      width: 65%;
      margin: 0 auto;
      text-align: center;
      z-index: 9999;
      color: #0F2764;

      @include mq(md) {
        width: 95%;
      }

      h2 {
        font-size: 36px;
        line-height: 40px;
        font-weight: 700;

        @include mq(md) {
          font-size: 28px;
          line-height: 32px;
        }
      }

      p {
        font-size: 18px;
      }

      .btn {
        background-color: #254383;
        margin: 0 auto;
        border-color: #254383;

        &:hover {
          background-color: transparent;
          color: #254383;
        }
      }
    }
  }
}

.productBox {
  background-color: $white;
  border-radius: 20px;
  padding: 53px 53px 40px;

  @include mq(md) {
    padding: 35px 25px;
  }

  .top {
    display: flex;

    @include mq(md) {
      flex-direction: column;
    }

    .packshot {
      width: 60%;
      margin-left: auto;
      margin-top: -135px;
      margin-right: -125px;

      @include mq(md) {
        width: 100%;
        margin-left: unset;
        margin-top: 10px;
        margin-right: unset;
      }
    }
  }

  .bottom {
    .dawki {
      display: flex;
      align-items: center;
      gap: 15px;
      border-top: 2px solid #F8F8F8;
      padding-top: 20px;

      @include mq(md) {
        align-items: flex-start;

        flex-wrap: wrap;
        margin-right: 0;
        gap: 10px;
      }

      &--right {
        position: relative;
        left: 35%;

        @include mq(xxl, min) {
          left: 30%;
        }

        @include mq(md) {
          left: 0;
        }
      }

      p {
        font-size: 16px;
        padding-bottom: 0;
        margin-bottom: 0;

        &:not(.dawka) {
          color: #254383;
          font-weight: 400;
        }

        @include mq(md) {
          &:first-of-type {
            width: 100%;
          }
        }
      }

      .dawka {
        font-size: 13px;
        line-height: 15px;
        padding: 15px;
        border: 2px solid #EFEFF0;
        border-radius: 27px;
        color: $black;

        @include mq(md) {
          padding: 15px 20px;
          margin-bottom: 7px;
          margin-right: 7px;
        }
      }

      .spacer {
        width: 2px;
        height: 35px;
        background-color: #EFEFF0;
        margin: 0 50px;
        padding-top: 0;

        @include mq(md) {
          display: none;
        }
      }
    }
  }

  &__text {
    h2 {
      font-size: 42px;
      line-height: 45px;
      font-weight: 700;
      margin-bottom: 30px;
      color: $secondary;
      margin-top: 55px;

      @include mq(md) {
        font-size: 28px;
        line-height: 31px;
      }
    }

    .buttons {
      margin-top: 45px;
      display: flex;
      gap: 20px;

      @include mq(md) {
        flex-direction: column;
      }
    }
  }
}

.home {
  &__award {
    width: 280px;
    height: auto;
    object-fit: contain;
    object-position: center;

    @include mq(md) {
      width: 245px;
      margin: -20px auto 0 !important;
    }
  }

  &__rules-container{
    position: relative;
    padding: 35px 35px 35px 45px;
    background-color: rgba(255, 255, 255, 0.45);
    -webkit-backdrop-filter: blur(13px);
    backdrop-filter: blur(13px);
    filter: brightness(1.3);
    border-radius: 20px;
    color: $black;

    @include mq(md) {
      padding: 30px 25px;
    }

    & .first_box{
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    & img.doctor{
      position: absolute;
      width: 37%;
      right: 0;

      @media only screen and (min-width: 1400px) and (max-width: 4000px) {
        width: 28%;
      }
    }

    &--big {
      padding: 50px 30px 30px 38px;

      @include mq(md) {
        padding: 35px 25px 25px 25px;
      }
    }

    h2 {
      color: $secondary;
    }

    &--produkty {
      margin-bottom: 30px;
      padding: 35px 0 65px;
    }
  }

  &__rules {
    &--article {
      z-index: 1;
      background-size: contain;
      background-position: right center;
      background-repeat: no-repeat;

      .home__medicineContent div {
        padding-bottom: 0;
      }

      @include mq(md) {
        min-height: 750px;
        background-position: right bottom;
      }
    }
  }

  &__medicine{
    position: relative;

    @include mq(md) {
      margin-bottom: 50px;
    }

    h2{
      font-size: 2.625rem;
      line-height: 45px;
      font-weight: 700;
      margin-top: 0;
      margin-bottom: 25px;

      @include mq(md) {
        font-size: 28px;
        line-height: 32px;
      }
    }

    p{
      font-size: 1.5rem;
      line-height: 30px;
      padding-bottom: 40px;
      color: #9C9C9C;
    }
    ul{
      padding-bottom: 30px;
    }
    ul li{
      font-size: 1.3rem;
      line-height: 30px;
      color: #9C9C9C;
      list-style: none;
      margin-bottom: 17px;
    }
    ul li::before {
      content: "\2022";
      color: #E50C1B;
      font-weight: bold;
      display: inline-block; 
      width: 1em;
      margin-left: -1em;
    }
  }

  &__medicineLogo {
    margin-bottom: 35px;
    width: auto;
    height: 47px;
  }

  &__medicineImage {
    height: 100%;
    width: auto;
    object-fit: cover;
    object-position: center;
    border-radius: 0 0 11px 11px;
  }

  &__medicineContent {
    display: flex;
    position: relative;

    @include mq(md) {
      position: unset;
      flex-direction: column;
    }

    &--right {
      img {
        &:first-of-type {
          margin-right: 100px;

          @include mq(md) {
            margin-right: 0;
          }
        }
      }

      .home {
        &__boxItems {
          @include mq(md) {
            margin-bottom: 15px;
          }
          .item {
            &:nth-child(2) {
              img {
                width: 28px;
                height: 28px;
              }
            }

            &:nth-child(1) {
              img {
                height: 40px;
              }
            }

            &:last-of-type {
              img {
                height: 30px;
              }
            }
          }
        }
      }
    }

    &--article {

      .home__boxHeader {
        color: $primary;
      }

      p {
        &:last-of-type {
          width: 50%;

          @include mq(md) {
            width: 100%;
          }
        }
      }
    }

    img {
      height: 475px;
      width: auto;
      object-position: center;
      object-fit: contain;

      @include mq(md) {
        height: 220px;
      }
    }

    .primary {
      color: $primary;
    }

    .secondary {
      color: $secondary;
    }

    div {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding-bottom: 40px;
    }

    p {
      font-size: 18px;
      line-height: 26px;
      font-family: Poppins, sans-serif;
      color: $black;
      width: 70%;

      @include mq(xxl, min) {
        width: 55%;
      }

      @include mq(md) {
        width: 100%;
      }
    }
  }

  &__boxHeader {
    font-size: 20px !important;
    line-height: 26px !important;
    padding-bottom: 0 !important;
    margin-bottom: 17px !important;
    text-transform: uppercase;
  }

  &__boxItems {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding-bottom: 0 !important;

    .item {
      padding-bottom: 0;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      gap: 17px;

      p {
        margin-bottom: 0;
        padding-bottom: 0;
        width: 100%;
      }

      img {
        height: 30px;
        width: auto;
        min-width: 22px;
        margin-right: 0;
      }

      &:nth-child(2) {
        img {
          width: 28px;
          height: 28px;
        }
      }

      &:last-of-type {
        img {
          height: 40px;
        }
      }
    }
  }

  &__medicineDawki {
    display: flex;
    align-items: center;
    gap: 20px;
    margin-right: 30px;

    @include mq(md) {
      align-items: flex-start;
      flex-direction: column;
      flex-wrap: wrap;
      margin-right: 0;
      gap: 10px;
    }

    &--right {
      position: relative;
      left: 35%;

      @include mq(xxl, min) {
        left: 30%;
      }

      @include mq(md) {
        left: 0;
      }
    }

    p {
      font-size: 16px;
      font-family: Poppins, sans-serif;
      color: #C4C4C4;
      padding-bottom: 0;
      margin-bottom: 0;

      @include mq(md) {
        &:first-of-type {
          width: 100%;
        }
      }
    }

    .dawka {
      font-size: 15px;
      line-height: 15px;
      padding: 15px 34px;
      border: 2px solid #EFEFF0;
      border-radius: 27px;

      @include mq(md) {
        padding: 15px 20px;
      }
    }
  }

  &__rules {
    padding: 0 0 60px;
    position: relative;
    z-index: 999;

    &--first {
      padding: 25px 0
    }

    &--produkty {
      padding-bottom: 150px;
    }
  }

  &_rule {
    height: 100%;
    position: relative;

    @include mq(md) {
      min-height: 100%;
      padding: 85px 0;
    }

    p {
      margin-top: 5px;
      font-size: 21px;
      font-weight: 600;
      line-height: 25px;
      position: absolute;
      color: #fff;
      margin-bottom: 0;
      width: 90%;

      @include mq(md) {
        font-size: 20px;
      }
    }

    img {
      margin-bottom: 10px;
      width: 37px;
      height: auto;
      object-position: center;
      object-fit: contain;

      @include mq(md) {
        position: absolute;
        top: 0;
        left: 0;
      }
    }

  }

  &__ruleItems {
    display: flex;
    gap: 30px;

    @include mq(md) {
      flex-direction: column;
      gap: 15px;
      margin: 30px 0;
    }
  }

  &__ruleItem {
    padding: 23px;
    position: relative;
    background-color: $secondary;
    border-radius: 15px;
    flex: 1 1 31%;
    height: 233px;

    @include mq(md) {
      min-height: 200px;
    }
  }

  &__rulesNumber {
    font-size: 120px;
    line-height: 120px;
    font-weight: 400;
    color: rgba($white, .05);
    z-index: 0;
    position: absolute;
    bottom: -2%;
    right: 2%;
    font-family: "Poppins", sans-serif;

    @include mq(md) {
      right: -3.5%;
    }
  }

  &__rulesButtons {
    margin-top: 20px;
  }

  &__banner {
    margin-top: 90px;

    @include mq(md) {
      margin-top: 50px;
    }

    .banner {
      background-image: url("../../dist/img/banner.webp");
      height: 500px;
      padding: 0 46px;
      color: $white;
      position: relative;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;

      @include mq(md) {
        height: auto;
        padding: 30px;
        background-size: cover;
        background-position: left;
        background-repeat: no-repeat;
      }

      &__content {
        position: absolute;
        top: 50%;
        transform: translate(0, -50%);

        @include mq(md) {
          position: unset;
          top: unset;
          transform: unset;
        }

        h3 {
          margin-bottom: 15px;
          width: 75%;
          font-size: 48px;
          font-family: 'Besley', sans-serif;

          @include mq(md) {
            font-size: 32px;
          }
        }

        p {
          color: rgba($white, .6);
          font-size: 25px;
          line-height: 27px;
          font-family: 'Jost', sans-serif;

          @include mq(md) {
            font-size: 21px;
            line-height: 24px;
            margin-bottom: 35px;
          }
        }
      }

      &__app {
        position: absolute;
        top: 0;
        right: 0;
        max-height: 500px;

        @include mq(md) {
          position: unset;
        }
      }

      &__download {
        display: flex;
        flex-direction: row;
        gap: 15px;

        img {
          height: 55px;
          width: auto;

          @include mq(md) {
            height: 45px;
          }
        }
      }
    }
  }

  &__background {
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: -1;
    width: 660px;

    @include mq(md) {
      display: none;
    }
  }

  &__cards{
    h2{
      text-align: center;
      color: $secondary;
      margin: 65px auto 55px;
      font-size: 42px;

      @include mq(md) {
        font-size: 28px;
        line-height: 31px;
        margin: 40px 0;
      }
    }
  }

  &__products {
    .heading {
      text-align: center;
      margin-bottom: 45px;
      position: relative;

      &__logo{
        position: absolute;
        right: 100px;
        top: 0;
        max-width: 200px;

        @include mq(md){
          position: relative;
        }
      }

      h1 {
        margin-bottom: 0;
        color: $secondary;
        font-size: 47px;
        line-height: 56px;

        @include mq(md) {
          font-size: 40px;
          line-height: 47px;
        }
      }

      p {
        font-size: 29px;
        line-height: 34px;
        font-weight: 600;
        margin-bottom: 20px;
      }

      img {
        filter: brightness(0.85);
      }
    }

    .products {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      gap: 50px 10px;

      @include mq(md) {
        padding: 0 12px;
        gap: 30px 10px;
      }

      .item {
        text-align: center;
        width: fit-content;
        filter: brightness(0.87);

        &:last-of-type {
          img {
            @include mq(md) {
              max-width: 50%;
              margin: 0 auto;
            }
          }
        }

        img {
          height: 378px;
          width: auto;
          object-fit: contain;
          object-position: center;

          @include mq(md) {
            width: 100%;
            height: 100%;
          }
        }

        @include mq(md) {
          flex: 1 1 45%;
        }
      }
    }
  }
}

.chmurka {
  position: absolute;
  z-index: -1;

  &--1 {
    width: 270px;
    top: 185px;
    animation-name: move1;
    animation-duration: 10s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  }

  &--2 {
    width: 220px;
    top: 40px;
    right: -30px;
    animation-name: move2;
    animation-duration: 10s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  }
}

@keyframes move1 {
  0%   {left:0; top:185px;}
  25%  {left:60px; top:155px;}
  50%  {left:120px; top:185px;}
  75%  {left:60px; top:165px;}
  100% {left:0; top:185px;}
}

@keyframes move2 {
  0%   {right:-30px; top:40px;}
  25%  {right:20px; top:20px;}
  50%  {right:70px; top:40px;}
  75%  {right:20px; top:25px;}
  100% {right:-30px; top:40px;}
}

.balon {
  width: 225px;
  position: absolute;
  top: 290px;
  right: 0;
  animation-name: balon;
  animation-duration: 6s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;

  @include mq(md) {
    display: none;
  }
}

@keyframes balon {
  0%   {right:0; top:290px;}
  50%  {right:0; top:230px;}
  100% {right:0; top:290px;}
}

.cards__items {
  display: flex;
  gap: 45px;

  @include mq(md) {
    flex-direction: column;
  }
}

.scene {
  display: inline-block;
  flex: 1 1 31%;
  width: 100%;
  height: 100%;
  perspective: 600px;
}

.card {
  position: relative;
  width: 100%;
  height: 355px;
  cursor: pointer;
  transform-style: preserve-3d;
  transform-origin: center right;
  transition: all 1s;
}

.card.is-flipped {
  transform: translateX(-100%) rotateY(-180deg);
  height: 445px;

  @include mq(md) {
    height: 430px;
  }
}

.card__face {
  position: absolute;
  width: 100%;
  height: 100%;
  color: $secondary;
  font-weight: bold;
  font-size: 20px;
  backface-visibility: hidden;
  border-radius: 10px;
  padding: 35px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @include mq(md) {
    padding: 25px;
  }

  h3 {
    text-transform: uppercase;
    color: $primary;
    margin-bottom: 11px;
  }

  p {
    @include mq(md) {
      font-size: 16px;
    }
  }

  .btn {
    width: 100%;
  }
}

.card__face--front {
  background: $white;
}

.is-flipped {
  z-index: -999999;

  .card__face--front {
    z-index: -999999;
  }
}

.card__face--back {
  background: $secondary;
  transform: rotateY(180deg);
  color: $white;
  font-size: 17px;
  z-index: 999999;
  position: relative;

  a {
    position: relative;
    z-index: 9999999;
  }
}