.answers {
  padding: 0 0 40px;
  position: relative;
  z-index: 9;
  background-image: url("dist/img/page_answers_bg.webp");
  background-size: cover;
  background-position: center;

  @include mq(md){
    margin-top: auto;
    padding: 0 0 40px;
  }

  &__header {
    text-align: center;

    h1 {
      font-size: 32px;
      font-weight: 600;
      margin-bottom: 10px;
      color: $secondary;
      line-height: 40px;

      @include mq(md) {
        font-size: 28px;
        line-height: 32px;
      }
    }

    .sub {
      color: rgba($black, .6);
    }
  }

  &__total {
    font-size: 25px;
    line-height: 30px;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    margin-bottom: 0;
    text-align: center;

    span {
      color: $third;
      font-size: 32px;
      font-weight: 600;

      @include mq(md) {
        font-size: 26px;
        line-height: 32px;
      }
    }

    @include mq(md) {
      font-size: 18px;
      margin-top: 10px;
    }
  }

  &__info {
    font-size: 22px;
    font-family: 'Jost', sans-serif;
    margin-top: 40px;
    margin-bottom: 0;
  }

  &__single {
    background-color: $white;
    margin-top: 40px;
    padding: 30px;
    border-radius: 20px;

    & p{
      font-size: 18px;
      font-family: 'Poppins', sans-serif;
    }
  }

  &__question {
    font-size: 17px!important;
    font-weight: 600;
    color: $black;
    font-family: 'Poppins', sans-serif;
  }

  &__check {
    text-align: left;
    padding-top: 50px;
    margin-top: 40px;

    @include mq(md) {
      padding-top: 40px;
      margin-top: 30px;
    }

    p {
      margin-bottom: .5rem !important;
    }

    .correct {
      color: #34B44C;
      font-weight: 600;
    }

    .incorrect {
      color: #E15858;
      font-weight: 400;
    }

    strong {
      font-size: 25px;
      font-weight: 400 !important;

      @include mq(md) {
        font-size: 18px;
      }
    }
  }
}