.presentation {
  height: 100%;
  width: 100%;
  padding: 0;
  border-radius: 20px;
  background-size: contain;
  background-position: right center;
  background-repeat: no-repeat;
  position: relative;

  @include mq(md) {
    width: 100%;
    height: 100%;
    padding: 0;
    border-radius: 22px;
    background-position: right bottom;
    background-image: unset !important;
  }

  img {
    width: 100%;
  }

  &__title {
    display: flex;
    align-items: center;
    gap:  30px;
    margin-bottom: 40px;

    @include mq(md) {
      flex-direction: column;
      align-items: flex-start;
    }

    .number {
      margin-bottom: 0 !important;
    }

    p {
      &:last-of-type {
        font-family: BloggerSans, sans-serif;
        font-size: 40px;
        line-height: 42px;
        font-weight: 600;
        color: $secondary;

        @include mq(md) {
          font-size: 32px;
          line-height: 34px;
        }
      }
    }
  }

  &__icons {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 97%;

    .item {
      display: flex;
      gap: 18px;

      img {
        min-width: 54px;
        max-height: 40px;
        object-fit: contain;
        object-position: center;
      }
    }
  }

  &__content {
    .content {
      width: 78%;

      @include mq(md) {
        width: 100%;
      }
    }

    h2 {
      font-size: 52px;
      line-height: 48px;

      @include mq(md) {
        font-size: 38px;
        line-height: 34px;
      }
    }

    p {
      font-size: 22px;
      line-height: 31px;
      font-family: 'Poppins', sans-serif;
      margin-bottom: 0;

      @include mq(md) {
        font-size: 18px;
        line-height: 27px;
      }

      span {
        color: #31AC48;
        font-weight: 600;
      }
    }

    .green {
      font-family: 'Poppins', sans-serif;
      font-size: 28px;
      line-height: 32px;
      font-weight: 300;
      color: #31AC48;
    }

    .number {
      font-size: 110px;
      line-height: 105px;
      font-weight: 600;
      font-family: BloggerSans, sans-serif;
      color: $secondary;
      margin-bottom: 20px;

      @include mq(md) {
        font-size: 80px;
        line-height: 75px;
        margin-bottom: 10px;
      }
    }
  }

  .info {
    position: absolute;
    bottom: 0;
    font-size: 17px;
    font-family: 'Poppins', sans-serif;
    color: #B2C2CB;

    @include mq(md) {
      position: unset;
      font-size: 16px;
      margin-bottom: 0;
    }
  }

  .world {
    width: 70%;
    height: 100px;
    transition: all .5s;
  }

  .magic {
    position: relative;
    transition: all .5s;

    &__text {
      opacity: 0;
      position: absolute;
      top: 0;
      transition: all .5s;
      color: #31AC48;
      font-size: 21px;
      font-weight: 600;
      width: 79%;

      @include mq(md) {
        width: 100%;
      }
    }

    &:hover {
      .confetti {
        opacity: 0;
      }

      .magic__text {
        opacity: 1;
      }
    }

    $yellow: #ffd300;
    $blue: #3A8AFF;
    $pink: #ff4e91;

    $duration: 900;

    @function randomNum($min, $max) {
      $rand: random();
      $randomNum: $min + floor($rand * (($max - $min) + 1));

      @return $randomNum;
    }

    .icon {
      font-size: 32px;
      font-weight: bold;
      letter-spacing: 32px;
      position: relative;
    }

    .confetti {
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      width: 80%;
      height: 75px;
      overflow: hidden;
      transition: all .5s;
      margin-left: -10px;

      @include mq(md) {
        width: 100%;
      }
    }

    .confetti-piece {
      position: absolute;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background: $blue;
      top: 0;
      opacity: 0;

      @for $i from 1 through 42 {
        &:nth-child(#{$i}) {
          left: $i * 6%;
          transform: rotate(#{randomNum(-80, 80)}deg);
          animation: makeItRain $duration * .7ms infinite ease-out;
          animation-delay: #{randomNum(0, $duration * .4)}ms;
          animation-duration: #{randomNum($duration * .5, $duration * .8)}ms
        }
      }

      &:nth-child(odd) {
        background: $blue;
      }

      &:nth-child(even) {
        z-index: 1;
      }

      &:nth-child(4n) {
        width: 8px;
        height: 8px;
        animation-duration: $duration * .8ms;
      }

      &:nth-child(3n) {
        width: 8px;
        height: 8px;
        animation-duration: $duration * 2ms;
        animation-delay: $duration * 1ms;
      }
    }

    @keyframes makeItRain {
      from {
        opacity: 0;
      }

      50% {
        opacity: 1;
      }

      to {
        transform: translateY(90px);
      }
    }
  }

  &__buttons {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    p {
      margin-bottom: 0;
      font-size: 25px;
      font-family: 'Poppins', sans-serif;

      @include mq(md) {
        font-size: 16px;
      }
    }

    .btn {
      @include mq(md) {
        padding: 12px 20px;
      }

      &__primary {
        @include mq(md) {
          min-width: 110px;
        }
      }
    }
  }

  &__przypisy {
    margin-top: 45px;
    font-size: 11px;
    line-height: 24px;

    p {
      margin-bottom: 0;
    }
  }
}

#q0 {
  .presentation {
    @include mq(md) {
      height: auto;
    }
  }
}

#q1 {
  .presentation {
    @include mq(md) {
      height: auto;
    }
  }
}

#q2 {
  .presentation {
    @include mq(md) {
      height: auto;

      .confetti {
        height: 170px;
      }
    }
  }
}

#q3 {
  .presentation {
    @include mq(md) {
      height: auto;

      .confetti {
        height: 135px;
      }
    }
  }
}

#q4 {
  .presentation {
    @include mq(md) {
      height: auto;

      .confetti {
        height: 135px;
      }
    }
  }
}

#q5 {
  .presentation {
    @include mq(md) {
      height: auto;
    }
  }
}

#q6 {
  .presentation {
    @include mq(md) {
      height: auto;
    }
  }
}

#q7 {
  .presentation {
    @include mq(md) {
      height: auto;

      .confetti {
        height: 230px !important;
      }
    }
  }
}