.quiz {
  margin-top: 40px;
  margin-bottom: 40px;
  padding-bottom: 40px;
  position: relative;
  z-index: 9;
  //background-image: url("../../dist/img/check-bg.webp");

  @include mq(md) {
    margin-top: 20px;
  }

  &__content {
    width: 100%;
    margin: 0 auto 20px;
    padding: 40px;
    background-color: $white;
    border-radius: 20px;

    @include mq(md) {
      width: 100%;
      padding: 20px;
    }

    .quiz__buttons {
      flex-direction: row;
      justify-content: space-between;
    }
  }

  &__header {
    position: relative;
    padding-bottom: 20px;
    text-align: center;

    @include mq(md) {
      flex-direction: column;
      align-items: center;
    }

    p {
      margin-bottom: 0;
      font-size: 22px;
      font-weight: 300;
      color: $secondary;

      @include mq(md) {
        font-size: 18px;
      }
    }
  }

  &__text {
    text-align: center;

    h1 {
      font-weight: 400 !important;
      font-size: 32px !important;
      color: $black !important;
    }

    h2 {
      font-size: 32px !important;
      color: $secondary;
      margin-top: 30px;
    }

    p {
      width: 75%;
      margin: 0 auto 30px;
    }
  }

  &__topText {
    font-size: 24px !important;

    @include mq(md) {
      font-size: 21px !important;
      margin-bottom: 5px !important;
    }
  }

  &__time {
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    align-items: center;
    font-size: 20px;
    line-height: 20px;
    color: #B5C7D1 !important;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;

    img {
      margin-right: 8px;
      height: 25px;
      width: 25px;
    }
  }

  &__body {

    h2 {
      text-align: center;
      font-size: 28px;
      line-height: 34px;
      margin: 0 auto 30px;
      width: 85%;

      @include mq(md) {
        font-size: 21px;
        line-height: 24px;
      }
    }
  }

  &__answers {
    display: flex;
    gap: 20px;
    flex-direction: column;

    &--img {
      flex-direction: row;

      @include mq(md) {
        flex-direction: column;
      }
    }
  }

  &__answer {
    width: 100%;
    background-color: #F9F9F9;
    border: 1px solid #D8D8D8;
    font-size: 20px;
    line-height: 22px;
    font-weight: 400;
    position: relative;
    cursor: pointer;
    border-radius: 50px;
    font-family: 'Poppins', sans-serif;

    @include mq(md) {
      font-size: 16px;
      margin-bottom: 10px;
    }

    input {
      position: absolute;
      width: 100%;
      height: 100%;
      opacity: 0;
      left: 0;
      cursor: pointer;
    }

    input:hover ~ label {
      background-color: $secondary;
      color: $white;
    }

    input:checked ~ label {
      background-color: $secondary;
      color: $white;
    }

    label {
      padding: 16px 47px;
      width: 100%;
      height: 100%;
      transition: all .4s;
      cursor: pointer;
      background-color: #F9F9F9;
      color: $black;
      border-radius: 50px;

      @include mq(md) {
        padding: 16px 32px;
      }
    }
  }

  &__progress {
    height: 3px;
    width: 100%;
    background-color: rgba($black, .22);
    margin-top: 30px;
    position: relative;

    &--0 {
      &:before {
        display: block;
        content: '';
        width: 15%;
        height: 3px;
        background-color: #0e2568;
        position: absolute;
        top: 0;
        left: 0;
      }
    }
    &--1 {
      &:before {
        display: block;
        content: '';
        width: 30%;
        height: 3px;
        background-color: #0e2568;
        position: absolute;
        top: 0;
        left: 0;
      }
    }
    &--2 {
      &:before {
        display: block;
        content: '';
        width: 45%;
        height: 3px;
        background-color: #0e2568;
        position: absolute;
        top: 0;
        left: 0;
      }
    }
    &--3 {
      &:before {
        display: block;
        content: '';
        width: 60%;
        height: 3px;
        background-color: #0e2568;
        position: absolute;
        top: 0;
        left: 0;
      }
    }
    &--4 {
      &:before {
        display: block;
        content: '';
        width: 75%;
        height: 3px;
        background-color: #0e2568;
        position: absolute;
        top: 0;
        left: 0;
      }
    }
    &--5 {
      &:before {
        display: block;
        content: '';
        width: 100%;
        height: 3px;
        background-color: #0e2568;
        position: absolute;
        top: 0;
        left: 0;
      }
    }
  }

  &__buttons {
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 15px;

    @include mq(md) {
      gap: 10px;
    }

    p {
      margin-bottom: 0;
      font-size: 19px;
      font-family: 'Poppins', sans-serif;

      @include mq(md) {
        font-size: 16px;
      }

      span {
        color: red;
      }
    }

    button {
      min-width: 150px;

      @include mq(md) {
        min-width: unset;
      }
    }

    .btn {
      gap: 5px;
    }
  }

  &__green {
    color: $green;
  }

  &__finish {
    padding-top: 40px;
    padding-bottom: 100px;
    position: relative;
    z-index: 9;
    color: $white;
    font-family: "Poppins", sans-serif;

    h1 {
      font-weight: 700;
      font-size: 32px;
      line-height: 35px;
      margin-bottom: 20px;
      color: $white;
    }

    h3 {
      margin-top: 40px;
    }
  }

  &__finishBox {
    background-color: rgba(255, 255, 255, 0.1);
    -webkit-backdrop-filter: blur(20px);
    backdrop-filter: blur(20px);
    filter: brightness(1.07);
    border-radius: 20px;
    padding: 55px 55px 55px 35px;

    .btn__primary {
      filter: brightness(0.85);
    }
  }

  &__info {
    font-family: 'Poppins', sans-serif;
    font-size: 18px;
    line-height: 28px;
    color: $white;
    margin-bottom: 25px;
  }

  &__checkbox {
    display: flex;
    flex-direction: column;
    gap: 40px;
    margin-top: 20px;

    a {
      text-decoration: underline;
    }

    .red {
      &__alert {
        font-size: 14px;
        position: absolute;
        left: 0;
        bottom: -20px;
        color: #DE0C1A;
      }
    }

    label {
      display: flex;
      align-items: flex-start;
      gap: 15px;
      position: relative;

      p {
        margin-bottom: 0;
        font-size: 17px;
        color: $white;
        font-family: 'Poppins', sans-serif;
      }

      &:first-child {
        font-weight: 600;
      }

      input {
        border: 1px solid #C7C7C7;
        border-radius: 0 !important;
      }

      a {
        color: $white;
      }
    }
  }

  &__sticky {
    position: absolute;
    top: 0;
    right: 50px;
    width: 48%;
    height: 85%;
    z-index: 0;
    text-align: right;

    @include mq(xxl, min) {
      height: 75%;
    }

    @include mq(md) {
      position: unset;
      height: unset;
      width: 100%;
    }
  }

  &__finishImage {
    position: sticky;
    top: 200px;
    right: 0;
    z-index: -1;
    width: 60%;
    height: auto;
    object-fit: contain;

    @include mq(md) {
      margin-top: 50px;
    }

    @include mq(xxl, min) {
      width: 82%;
    }
  }

  &__more {
    margin-top: 20px;
    margin-bottom: 0;
    font-size: 16px;
    text-align: center;
    color: rgba($black, .4);
  }

  &__items {
    display: flex;
    flex-direction: column;
    gap: 20px;

    @include mq(md) {
      gap: 12px;
    }

    .item {
      padding: 12px 35px;
      border: 1px solid #CCDEE8;
      border-radius: 20px;
      text-align: center;
      min-width: 300px;

      @include mq(md) {
        min-width: 240px;
        padding: 7px 20px 6px;

        img {
          height: 18px;
          width: auto;
          object-position: center;
          object-fit: contain;
        }
      }
    }
  }

  &__sort {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 65%;
    margin: 0 auto;

    @include mq(md) {
      width: 100%;
    }

    ul {
      list-style: none;
      width: 100%;
      font-size: 0.75em;
      padding: 10px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      @include mq(md) {
        padding: 0 5px;
      }
    }

    li {
      float: left;
      height: 50px;
      width: 100%;
      max-width: 297px;
      margin: 10px;
      background: white;
      cursor: pointer;
      transition: all .2s ease-out;
      border: 1px solid #CCDEE8;
      border-radius: 50px;
      font-family: 'Poppins', sans-serif;

      @include mq(md) {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin: 5px;
      }
    }

    li:hover {
      transform: scale(1.05, 1.05);
    }

    li[data-selected] {
      transform: scale(1.05, 1.05);
      border: 2px solid #00A0DF;
    }

    #defs {
      li {
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          height: 50px;
          width: 100%;
          object-position: center;
          object-fit: contain;
          padding: 15px 15px;

          @include mq(md) {
            padding: 18px 15px;
          }
        }
      }
    }

    div#counter {
      position: absolute;
      left: 600px;
      margin: 1rem;
      font-size: 3em;
      display: none;
    }

    ul li span {
      width: 100%;
      height: 50px;
      display: block;
      padding: 12px 35px 11px 35px;
      transform: none;
      transition: opacity 0.2s ease-out;
      font-size: 18px;
      line-height: 24px;
      font-weight: 300;

      @include mq(md) {
        padding: 10px 15px 9px 18px;
        font-size: 13px;
        line-height: 18px;
        display: flex;
        align-items: center;
      }
    }

    span:hover {
      transform: none;
    }

    button {
      position: absolute;
      padding: 0;
      margin: 0;
      width: 100px;
      height: 30px;
      left: 640px;
      bottom: 0px;
      font-weight: bold;
      transition: background-color 0.3s ease-out;
      background-color: #00A0DF;
      border-radius: 3px;
      border: none;
      color: #fff;
      -webkit-box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 1);
      box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 1);
    }

    button:hover {
      background-color: #30BCF3;
      cursor: pointer;
    }

    button:active {
      box-shadow: none;
      outline: none;
      transform: scale(0.95, 0.95);
    }

    button:focus {
      outline: none;
    }

    .score {
      box-shadow: none;
      border: 2px solid #34B44C;
      background-color: #34B44C !important;

      span {
        font-weight: 500 !important;
        color: $white;
      }

      img {
        filter: brightness(0) invert(1);
      }
    }

    .score:hover {
      cursor: default;
      transform: none;
      box-shadow: none;
    }

    .fadeOut li span {
      transition: opacity 0.25s ease-out;
      opacity: 0;
    }

    .fadeOut li {
      transition: transform .5s ease-out;
      transform: rotateX(360deg);
    }
  }
}