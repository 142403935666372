.form {
  &__group {
    display: flex;
    flex-direction: row;
    gap: 20px;
    margin: 20px 0;

    @include mq(md) {
      flex-direction: column;
    }

    input {
      width: 100%;
      border: 1px solid $white;
      background-color: $white;
      padding: 10px 15px 10px 25px;
      font-size: 17px;
      font-family: 'Poppins', sans-serif;

      @include mq(md) {
        margin-bottom: 8px;
      }
    }
  }

  &__full {
    display: flex;
    flex-direction: column;
    margin: 20px 0;

    input {
      width: 100%;
      border: 1px solid $white;
      background-color: $white;
      padding: 10px 25px;
      font-size: 17px;
      font-family: 'Poppins', sans-serif;

      @include mq(md) {
        margin-bottom: 8px;
      }
    }
  }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

input:required {
  &::placeholder {
    &:before {
      content: '*';
      display: block;
      color: #FF0000;
    }
  }
}

input::placeholder {
  &:before {
    content: '*';
    display: block;
    color: #FF0000;
  }
}

input[type="checkbox"] {
  -webkit-appearance: none;
  appearance: none;
  background-color: #fff;
  margin: 0;
  font: inherit;
  color: #002668;
  min-width: 25px;
  min-height: 25px;
  border-radius: 0.15em;
  transform: translateY(-0.075em);
  display: grid;
  place-content: center;

  &::before {
    content: "✓";
    transform: scale(0);
    transition: 120ms transform ease-in-out;
  }

  &:checked::before {
    transform: scale(1);
  }
}

.red {
  color: #FF0000;
  font-weight: 600;
  font-size: 22px;
  line-height: 18px;
  margin-right: 8px;

  &--light {
    margin-top: 3px;
    color: #DE0C1A;
    font-size: 14px !important;
    font-weight: 300;
  }

  &__alert {
    font-size: 16px;
    margin-right: 0;
  }
}

.form__before {
  position: relative;
  width: 100%;

  #alertnpwz {
    margin-bottom: 0 ;
  }
}

.form__before:before {
  content: "*";
  color: red;
  font-weight: bold;
  display: block;
  position: absolute;
  top: 10px;
  left: 10px;
}

.form-control {
  border-radius: 0;
}

form input::-webkit-validation-bubble-message,
form select::-webkit-validation-bubble-message,
form textarea::-webkit-validation-bubble-message {
  display:none;
}